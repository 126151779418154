import axios from '../axios';
import { message } from 'antd';

const payments = {
    search:
        (realestate_id, seach_text, type, realestate) => async (dispatch) => {
            dispatch({
                type: 'REQUEST_DATA_SEARCH',
                status: true,
            });

            try {
                let { data } = await axios.get(
                    '/v1/admin/widgets/payments/search',
                    {
                        params: {
                            realestate_id,
                            seach_text,
                            type,
                            realestate,
                        },
                    }
                );

                dispatch({
                    type: 'DATA_SEARCH',
                    data: data || [],
                    seach_text,
                    type_request: type,
                });

                dispatch({
                    type: 'REQUEST_DATA_SEARCH',
                    status: false,
                });

                return;
            } catch (err) {
                dispatch({
                    type: 'REQUEST_DATA_SEARCH',
                    status: false,
                });

                return message.error(
                    'ошибка. обратитесь к системному администратору'
                );
            }
        },
    delete: (payment_id, realestate_id) => async (dispatch) => {
        try {
            let { data } = await axios.post(
                '/v1/admin/widgets/payments/delete',
                {
                    payment_id,
                }
            );

            let getPayments = await axios.get('/v1/admin/widgets/payments', {
                params: {
                    realestate_id,
                },
            });

            dispatch({
                type: 'PAYMENT_LIST_DATA',
                data: getPayments?.data || null,
            });

            dispatch({
                type: 'PAYMENT_SAVE_REQUEST',
            });

            return message.success(data);
        } catch (err) {
            return message.error(
                'ошибка. обратитесь к системному администратору'
            );
        }
    },
    save: (fields, closeForm, form) => async (dispatch) => {
        if (fields?.conditions && !fields?.conditions.length) {
            return message.error('Добавьте хотя бы 1 условие оплаты');
        }

        dispatch({
            type: 'PAYMENT_SAVE_REQUEST',
            status: true,
        });

        try {
            const { data } = await axios.post(
                '/v1/admin/widgets/payments/save',
                fields
            );

            let getPayments = await axios.get('/v1/admin/widgets/payments', {
                params: {
                    realestate_id: fields?.realestate_id,
                },
            });

            dispatch({
                type: 'PAYMENT_LIST_DATA',
                data: getPayments?.data || null,
            });

            dispatch({
                type: 'PAYMENT_SAVE_REQUEST',
            });

            closeForm();
            form.resetFields();

            return message.success(data);
        } catch (err) {
            dispatch({
                type: 'PAYMENT_SAVE_REQUEST',
            });

            return message.error(
                'ошибка. обратитесь к системному администратору'
            );
        }
    },
    getFormOfPayments: (realestate_id) => async (dispatch) => {
        dispatch({
            type: 'PAYMENT_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get('/v1/admin/widgets/payments', {
                params: {
                    realestate_id,
                },
            });

            dispatch({
                type: 'PAYMENT_LIST_DATA',
                data: data || null,
            });

            dispatch({
                type: 'PAYMENT_REQUEST',
            });
        } catch (err) {
            dispatch({
                type: 'PAYMENT_REQUEST',
            });

            return message.error(
                'ошибка. обратитесь к системному администратору'
            );
        }
    },
};

export default payments;
