import { FC, useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BookingNotification } from '../BookingNotification';
import { BookingContext } from '@/Services/context/booking';
import Config from '@/Config';
import * as gqls from '@/Services/apollo/gqls';
import closeImg from '@/Assets/close.svg';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles/sidebarFlatInfo.module.scss';
import { role } from '@/enums/roles';
import { bookingStatus } from '@/enums/bookingStatus';
import { message, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import FormSecondaryFlat from './helpers/form';
import { Image } from 'antd';

export const SidebarFlatInfo: FC = () => {
    const dispatch = useDispatch();
    const paramsRoute = useParams();

    const stateUser = useSelector((state: any) => state.account.user);

    const [flatUpdate, flatUpdateData] = useMutation(gqls.builder.flatUpdate, {
        onError: (err) => {
            message.info('Ошибка, попробуйте позже');
        },
        refetchQueries: () => [
            {
                query:
                    stateUser?.role?.type === 'REALTOR'
                        ? gqls.builder.getChessOfRealEstateByRealtor
                        : gqls.builder.getChessOfRealEstate,
                variables: {
                    id: paramsRoute.id,
                    fields: {
                        id: paramsRoute.id,
                    },
                },
            },
        ],
    });

    const [flatSecondaryUpdate] = useMutation(gqls.builder.flatSecondary, {
        onError: (err: any) => {
            setSecondaryFlatForm(false);
            return notification.open({
                message: 'Ошибка',
                description: err?.message || 'ошибка',
            });
        },
        refetchQueries: () => [
            {
                query:
                    stateUser?.role?.type === 'REALTOR'
                        ? gqls.builder.getChessOfRealEstateByRealtor
                        : gqls.builder.getChessOfRealEstate,
                variables: {
                    id: paramsRoute.id,
                    fields: {
                        id: paramsRoute.id,
                    },
                },
            },
        ],
        onCompleted: (data: any) => {
            dispatch({
                type: 'UPDATE_FLAT',
                flat: data?.flatSecondary || null,
            });
            setSecondaryFlatForm(false);
            setSecondaryFlatEditForm(false);
            notification.open({
                message: 'Успешно',
                description: 'Информация успешно изменена',
                icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
            });
        },
    });

    const stateFlats = useSelector((state: any) => state.flats);
    const accountRole = stateUser.role?.type || null;

    const { booking } = useContext(BookingContext);
    const flat = stateFlats?.flat || null;

    const [metreSquarePrice, setMetreSquarePrice] = useState(
        flat.square_metre_price
    );
    const [sold, setSold] = useState(flat.sold);

    const [flatFullPrice, setFlatFullPrice] = useState(
        flat.square_metres * flat.square_metre_price
    );

    const [secondaryFlatForm, setSecondaryFlatForm] = useState(false);
    const [secondaryFlatEditForm, setSecondaryFlatEditForm] = useState(false);

    useEffect(() => {
        setSold(flat.sold);
    }, [flat.sold]);

    useEffect(() => {
        setMetreSquarePrice(flat.square_metre_price);
        setFlatFullPrice(flat.square_metres * flat.square_metre_price);
    }, [flat.square_metre_price, flat.square_metres]);

    const updateFlatEvent = () => {
        if (!flatUpdateData.loading) {
            flatUpdate({
                variables: {
                    fields: {
                        square_metre_price: String(metreSquarePrice),
                        sold: Boolean(sold),
                        flat_id: flat._id,
                        real_estate_id: paramsRoute?.id,
                    },
                },
            });

            setFlatFullPrice(flat.square_metres * metreSquarePrice);
        }
    };

    const [requestBooking] = useMutation(gqls.builder.requestBooking, {
        refetchQueries: () => [
            {
                query: gqls.builder.getChessOfRealEstateByRealtor,
                variables: {
                    id: paramsRoute?.id,
                    fields: {
                        id: paramsRoute?.id,
                    },
                },
            },
        ],
        onCompleted: (data) => {
            dispatch({
                type: 'UPDATE_FLAT',
                flat: data?.requestBooking || null,
            });
            toast.success('Запрос успешно отправлен');
        },
        onError: (err) => toast.error('Упс... Что-то пошло не так'),
    });

    const separateNumber = (number: number) => {
        return Math.round(number)
            .toString()
            .replace(/^([0-9]{2,3})?([0-9]{3})([0-9]{3}$)/, '$1 $2 $3');
    };

    const secondaryFlat = (formFields: any) => {
        flatSecondaryUpdate({
            variables: {
                fields: {
                    flat_id: flat._id,
                    type: 'SALE',
                    ...formFields,
                },
            },
        });
    };

    return (
        <>
            <FormSecondaryFlat
                edit={secondaryFlatEditForm}
                secondaryFlat={secondaryFlat}
                close={() => {
                    setSecondaryFlatEditForm(false);
                    setSecondaryFlatForm(false);
                }}
                statusForm={secondaryFlatForm}
            />

            <section className={styles.settingFlatForm}>
                <button
                    type="button"
                    onClick={() =>
                        dispatch({
                            type: 'UPDATE_FLAT',
                            flat: null,
                        })
                    }
                    className={styles.closeSidebar}
                >
                    <img src={closeImg} alt="закрыть" />
                </button>
                {accountRole === role.builder && (
                    <div className={styles.flatControl}>
                        <div>
                            <label className={styles.soldControl}>
                                Продан{' '}
                                <input
                                    type="checkbox"
                                    checked={sold}
                                    onChange={() => setSold(!sold)}
                                />
                            </label>
                            <label className={styles.priceControl}>
                                Цена за м<sup>2</sup> :
                                <input
                                    value={metreSquarePrice}
                                    onChange={(val) =>
                                        setMetreSquarePrice(val.target.value)
                                    }
                                />
                                ₸
                            </label>
                        </div>
                        <button
                            className={styles.updateFlatData}
                            type="button"
                            onClick={updateFlatEvent}
                        >
                            {flatUpdateData?.loading
                                ? 'Подождите...'
                                : 'Обновить'}
                        </button>
                    </div>
                )}

                <section className={styles.flatInfo}>
                    <div className={styles.flatInfoHeader}>
                        <a
                            href={`https://viewer.logovo.kz/${flat._id}`}
                            className={styles._360}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className={styles.tourIcon}>
                                <span>3D</span>
                                <span>ТУР</span>
                            </div>
                            <p>Посмотреть</p>
                        </a>
                        <h2 className={styles.flatInfoTitle}>
                            квартира №{flat.title}
                        </h2>
                    </div>

                    <Image
                        src={`${Config.public}${
                            flat?.template?.preview_photo.url || ''
                        }`}
                        alt="План жк"
                        preview={{
                            src: `${Config.public}${
                                flat?.template?.plan_photo?.url || ''
                            }`,
                            maskClassName: 'planImageWrapper',
                        }}
                        style={{
                            maxHeight: 170,
                            width: 'auto',
                            maxWidth: '85%',
                            display: 'block',
                            margin: '0 auto',
                        }}
                    />
                </section>

                {accountRole !== role.builder &&
                    flat?.booking !== bookingStatus.none &&
                    flat?.booking_notification !== role.builder && (
                        <BookingNotification
                            flat={flat}
                            bookingType={flat.booking}
                            flatID={flat._id}
                            flatNumber={flat.title}
                            disableCloseBtn
                        />
                    )}

                {accountRole === role.builder &&
                    flat?.booking !== bookingStatus.none &&
                    flat?.booking !== bookingStatus.canceled && (
                        <BookingNotification
                            flat={flat}
                            bookingType={flat.booking}
                            flatID={flat._id}
                            flatNumber={flat.title}
                            disableCloseBtn
                        />
                    )}

                {accountRole === role.builder &&
                    flat?.booking === bookingStatus.canceled &&
                    flat?.booking_notification === role.builder && (
                        <BookingNotification
                            flat={flat}
                            bookingType={flat.booking}
                            bookingNotificationRole={flat.booking_notification}
                            flatID={flat._id}
                            flatNumber={flat.title}
                            disableCloseBtn
                        />
                    )}

                <section className={styles.detailedInfo}>
                    <div>
                        <span>Площадь</span>
                        <span>
                            {flat.square_metres} м<sup>2</sup>
                        </span>
                    </div>
                    <div>
                        <span>Стоимость квартиры</span>
                        <span>{separateNumber(flatFullPrice)} ₸</span>
                    </div>
                    <div>
                        <span>
                            Стоимость м<sup>2</sup>
                        </span>
                        <span>{separateNumber(metreSquarePrice)} ₸</span>
                    </div>
                    <div>
                        <span>Этаж</span>
                        <span>{flat.floor}</span>
                    </div>
                    <div>
                        <span>Высота потолка</span>
                        <span>{flat?.template?.ceiling_height || 0} м</span>
                    </div>
                </section>

                {accountRole === role.realtor &&
                    (!flat?.secondary || flat?.secondary) &&
                    flat?.sold && (
                        <>
                            <button
                                type="button"
                                className={styles.requestBooking}
                                onClick={() => {
                                    setSecondaryFlatForm(true);
                                    setSecondaryFlatEditForm(false);
                                }}
                            >
                                Выставить на продажу
                            </button>
                        </>
                    )}

                {accountRole === role.realtor &&
                    flat?.secondary &&
                    !flat?.sold &&
                    stateUser?._id === flat?.secondary_info?.realtor?._id && (
                        <>
                            <button
                                type="button"
                                className={styles.requestBooking}
                                onClick={() => {
                                    setSecondaryFlatForm(true);
                                    setSecondaryFlatEditForm(true);
                                }}
                            >
                                Редактировать
                            </button>
                        </>
                    )}

                {accountRole === role.realtor &&
                    flat?.secondary &&
                    !flat?.sold &&
                    flat?.secondary_info.realtor && (
                        <div className={styles.infoRealtor}>
                            <h6>Квартира управляется риэлтором</h6>
                            <a
                                href={`tel: +7${flat?.secondary_info.realtor?.phone}`}
                            >{`+7${flat?.secondary_info.realtor?.phone}`}</a>
                        </div>
                    )}

                {accountRole === role.realtor &&
                    flat?.secondary &&
                    !flat?.sold &&
                    !flat?.secondary_info.realtor && (
                        <div className={styles.infoRealtor}>
                            <h6>
                                Риэлтор не найден в базе. Вы можете
                                переназначить квартиру под свой контроль
                            </h6>
                            <button
                                type="button"
                                className={styles.requestBooking}
                                onClick={() => {
                                    setSecondaryFlatForm(true);
                                    setSecondaryFlatEditForm(false);
                                }}
                            >
                                Выставить на продажу
                            </button>
                        </div>
                    )}

                {accountRole !== role.builder &&
                    (!flat?.booking ||
                        (flat?.booking !== bookingStatus.success &&
                            flat?.booking !== bookingStatus.waiting)) &&
                    !flat.sold &&
                    !flat?.secondary && (
                        <button
                            type="button"
                            className={styles.requestBooking}
                            onClick={() =>
                                requestBooking({
                                    variables: {
                                        fields: {
                                            id: flat._id,
                                            booking: bookingStatus.waiting,
                                            booking_notification: role.realtor,
                                        },
                                    },
                                })
                            }
                        >
                            Запросить бронь
                        </button>
                    )}

                {booking.managers &&
                booking.managers.length &&
                flat.booking !== null &&
                flat?.booking !== bookingStatus.none &&
                flat?.booking !== bookingStatus.canceled ? (
                    <section className={styles.sectionNotification}>
                        <h3>Менеджеры</h3>
                        {booking.managers.map((manager: any) => (
                            <div
                                key={manager._id}
                                className={styles.managerItem}
                            >
                                <p>{manager.firstname}</p>
                                <a href={`tel:+7${manager?.phone}`}>
                                    +7{manager?.phone}
                                </a>
                            </div>
                        ))}
                    </section>
                ) : (
                    <></>
                )}
            </section>
        </>
    );
};
