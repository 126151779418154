import axios from '../axios';
import { message } from 'antd';

const account = {
    resetConnectionBitrix24: () => async (dispatch) => {
        try {
            const { data } = await axios.post(
                `/v1/admin/users/builder/reset-connection`
            );

            dispatch({
                type: 'RESET_BUILDER_CONNECTION_BX24',
            });

            message.success('Настройки подключения успешно сброшены');
        } catch (err) {
            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    getBuilderCrmStatuses: () => async (dispatch) => {
        try {
            const { data } = await axios.get(`/v2/admin/builder/crm/statuses`);

            dispatch({
                type: 'UPDATE_CRM_INTEGRATION_INFORMATION',
                data,
            });
        } catch (err) {
            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    updateButrixStatuses: (key) => async (dispatch) => {
        try {
            const { data } = await axios.post(
                `/v2/crm-integration/bx24/realestate/list`,
                {
                    key,
                }
            );

            dispatch({
                type: 'UPDATE_BUILDER_STATUSES',
                data: data?.data?.statuses,
            });

            message.success('Статусы успешно обновлены');
        } catch (err) {
            console.log(22, err?.response);
            message.warning(
                err?.response?.data?.message ||
                    'Упс, ошибка. Проверьте Ваши права'
            );
        }
    },
    changeStatusIntegrationCrm: (params) => async () => {
        try {
            await axios.post(
                `/v1/admin/users/builder/update-status-integration-crm`,
                params
            );
        } catch (err) {
            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    generateIntegrationKeyApi: (integration_name) => async (dispatch) => {
        dispatch({
            type: 'STATUS_REQUEST_GENERATE_API_KEY',
            status: true,
        });

        try {
            const { data } = await axios.post(
                `/v1/admin/users/builder/generate-api-key`,
                {
                    integration_name,
                }
            );

            dispatch({
                type: 'UPDATE_BUILDER_API_KEY',
                data,
            });

            dispatch({
                type: 'STATUS_REQUEST_GENERATE_API_KEY',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'STATUS_REQUEST_GENERATE_API_KEY',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    deleteNote: (note_id) => async (dispatch) => {
        dispatch({
            type: 'ACCOUNT_NOTES_REQUEST',
            status: true,
        });

        try {
            await axios.delete(`/v1/admin/users/builder/notes`, {
                params: {
                    note_id,
                },
            });

            let { data } = await axios.get(`/v1/admin/users/builder/notes`);

            dispatch({
                type: 'ACCOUNT_NOTES_DATA',
                data,
            });

            dispatch({
                type: 'ACCOUNT_NOTES_REQUEST',
                status: false,
            });

            message.warning('Заметка успешно удалена');

            return;
        } catch (err) {
            dispatch({
                type: 'ACCOUNT_NOTES_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    updateNote: (values, close) => async (dispatch) => {
        dispatch({
            type: 'ACCOUNT_NOTES_REQUEST',
            status: true,
        });

        try {
            await axios.post(`/v1/admin/users/builder/notes`, values);
            let { data } = await axios.get(`/v1/admin/users/builder/notes`);

            message.success('Данные успешно обновлены');

            dispatch({
                type: 'ACCOUNT_NOTES_DATA',
                data,
            });

            dispatch({
                type: 'ACCOUNT_NOTES_REQUEST',
                status: false,
            });

            close();
        } catch (err) {
            dispatch({
                type: 'ACCOUNT_NOTES_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    getNotes: () => async (dispatch) => {
        dispatch({
            type: 'ACCOUNT_NOTES_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/users/builder/notes`);

            dispatch({
                type: 'ACCOUNT_NOTES_DATA',
                data,
            });

            dispatch({
                type: 'ACCOUNT_NOTES_REQUEST',
                status: false,
            });
        } catch (err) {
            dispatch({
                type: 'ACCOUNT_NOTES_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    saveKeysWhatsappBusiness: (fields) => async (dispatch) => {
        dispatch({
            type: 'UPDATE_SETTINGS_REQUEST',
            status: true,
        });

        try {
            await axios.post(
                '/v1/admin/users/builder/whatsapp-business-settings',
                fields
            );

            dispatch({
                type: 'UPDATE_SETTINGS_REQUEST',
                status: false,
            });

            return message.success('Данные успешно сохранены');
        } catch (err) {
            message.warning('Что-то пошло не так, попробуйте позже');

            return dispatch({
                type: 'UPDATE_SETTINGS_REQUEST',
                status: false,
            });
        }
    },
    deletePaymentMethod: (id) => async (dispatch) => {
        try {
            await axios.delete(`/v1/admin/users/builder/payment-methods`, {
                params: {
                    id,
                },
            });
            let { data } = await axios.get(
                `/v1/admin/users/builder/payment-methods`
            );

            return dispatch({
                type: 'PAYMENT_METHODS_DATA',
                data,
            });
        } catch (err) {
            console.log('error deletePaymentMethod -> ', err);

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    updatePaymentMethod: (values, close, resetFields) => async (dispatch) => {
        if (!values?.rates || !values?.rates.length) {
            return message.warning('Добавьте хотя бы 1 тариф');
        }

        dispatch({
            type: 'FORM_PAYMENT_METHODS_REQUEST',
            status: true,
        });

        try {
            await axios.post(`/v1/admin/users/builder/payment-methods`, values);
            let { data } = await axios.get(
                `/v1/admin/users/builder/payment-methods`
            );

            message.success('Данные успешно обновлены');

            dispatch({
                type: 'PAYMENT_METHODS_DATA',
                data,
            });

            dispatch({
                type: 'FORM_PAYMENT_METHODS_REQUEST',
                status: false,
            });
            resetFields();
            close();
        } catch (err) {
            console.log(445, err);

            dispatch({
                type: 'FORM_PAYMENT_METHODS_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    getPaymentMethods: () => async (dispatch) => {
        dispatch({
            type: 'PAYMENT_METHODS_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get(
                `/v1/admin/users/builder/payment-methods`
            );

            dispatch({
                type: 'PAYMENT_METHODS_REQUEST',
                status: false,
            });

            dispatch({
                type: 'PAYMENT_METHODS_DATA',
                data,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'PAYMENT_METHODS_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    deleteMortgage: (mortgage_id) => async (dispatch) => {
        dispatch({
            type: 'ACCOUNT_MORTGAGE_REQUEST',
            status: true,
        });

        try {
            await axios.delete(`/v1/admin/users/builder/mortgage`, {
                params: {
                    mortgage_id,
                },
            });

            let { data } = await axios.get(`/v1/admin/users/builder/mortgage`);

            dispatch({
                type: 'ACCOUNT_MORTGAGE_REQUEST',
                status: false,
            });

            dispatch({
                type: 'ACCOUNT_BUILDER_MORTGAGE',
                data,
            });

            message.warning('Ипотека успешно удалена');

            return;
        } catch (err) {
            dispatch({
                type: 'ACCOUNT_MORTGAGE_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    getMortgage: () => async (dispatch) => {
        dispatch({
            type: 'ACCOUNT_MORTGAGE_REQUEST',
            status: true,
        });

        try {
            let { data } = await axios.get(`/v1/admin/users/builder/mortgage`);

            dispatch({
                type: 'ACCOUNT_MORTGAGE_REQUEST',
                status: false,
            });

            dispatch({
                type: 'ACCOUNT_BUILDER_MORTGAGE',
                data,
            });

            return;
        } catch (err) {
            dispatch({
                type: 'ACCOUNT_MORTGAGE_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
    updateMortgage: (values, close) => async (dispatch) => {
        dispatch({
            type: 'ACCOUNT_MORTGAGE_REQUEST',
            status: true,
        });

        try {
            await axios.post(`/v1/admin/users/builder/mortgage`, values);
            let { data } = await axios.get(`/v1/admin/users/builder/mortgage`);

            message.success('Данные успешно обновлены');

            dispatch({
                type: 'ACCOUNT_BUILDER_MORTGAGE',
                data,
            });

            dispatch({
                type: 'ACCOUNT_MORTGAGE_REQUEST',
                status: false,
            });

            close();
        } catch (err) {
            dispatch({
                type: 'ACCOUNT_MORTGAGE_REQUEST',
                status: false,
            });

            message.warning('Упс, ошибка. Проверьте Ваши права');
        }
    },
};

export default account;
